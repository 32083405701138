import { createSlice } from '@reduxjs/toolkit'

const todosSlice = createSlice({
  name: 'todos',
  initialState: [
    {
      text: 'Learn about React',
      isCompleted: false
    },
    {
      text: 'Meet friend for lunch',
      isCompleted: false
    },
    {
      text: 'Build really cool todo app',
      isCompleted: false
    }
  ],
  reducers: {
    addTodo(state, action) {
      state.push({text: action.payload, isCompleted: false})
    },
    toggleTodo(state, action) {
      const { index } = action.payload;
      state[index].isCompleted = !state[index].isCompleted;
    }
  }
})

// 'ducks' pattern
// named export of action creators
export const { addTodo, toggleTodo } = todosSlice.actions;
// default export of reducer function
export default todosSlice.reducer;
