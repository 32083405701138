import React from "react";
import RsvpSearch from "./RsvpSearch";
import RsvpForm from "./RsvpForm";

function Rsvp() {
  return (
    <div id="rsvp">
      <RsvpSearch />
      <RsvpForm />
    </div>
  );
}

export default Rsvp;
