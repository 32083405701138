import React, { Fragment } from "react";
import { Parallax } from "react-parallax";
import CountDown from "./Countdown";

const image1 = "images/us/header.jpg";

function Header() {
  return (
    <Fragment>
      <Parallax
        bgImage={image1}
        strength={300}
        bgImageStyle={{ opacity: ".8" }}
      >
        <div className="viewport" style={{ height: "80vh" }}>
          <div className="insideHeader tag">
            Eva &amp; Nelson
            <br />
            <span className="date">16th May 2021</span>
          </div>

          <div className="insideHeader countdown">
            <CountDown DateTo="2021-05-16 23:59" />
          </div>
        </div>
      </Parallax>
    </Fragment>
  );
}

export default Header;
