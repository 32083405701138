import React, { useState } from "react";
import axios from "axios";
import { useDispatch } from "react-redux";
import { storeGuestlist, errorResult } from "./rsvpSlice";

function RsvpSearch() {
  const [value, setValue] = useState("");
  const [loading, setLoading] = useState("");
  const dispatch = useDispatch();

  // once off ping to warm up lambda
  useState(() => {
    axios.get(`${process.env.REACT_APP_RSVP_SERVICE}ping`);
  });

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!value) return;

    // let url = ?name=${value}`;

    setLoading("Searching...");
    axios
      .get(`${process.env.REACT_APP_RSVP_SERVICE}findguests`, {
        params: {
          name: value.trim(),
        },
      })
      .then((response) => dispatch(storeGuestlist(response.data)))
      .catch((error) => {
        dispatch(errorResult());
      })
      .finally(() => {
        setLoading("");
        document.getElementById("rsvp").scrollIntoView();
      });
    setValue("");
  };
  return (
    <form onSubmit={handleSubmit}>
      <input
        type="search"
        className="input"
        value={value}
        onChange={(e) => setValue(e.target.value)}
      />
      <input type="submit" value="Lookup" />
      <div id="searchLoading">{loading}</div>
    </form>
  );
}

export default RsvpSearch;
