import { createSlice } from '@reduxjs/toolkit'

const counterSlice = createSlice({
  name: 'counter',
  initialState: { value: 0 },
  reducers: {
    increment(state, action) {
        state.value++;
    }
  }
})

// 'ducks' pattern
// named export of action creators
export const { increment } = counterSlice.actions;
// default export of reducer function
export default counterSlice.reducer;
