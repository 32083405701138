import React, { Fragment } from 'react'

function Map() {
    return (
        <Fragment>
            <div className="google-maps">
                <iframe title="Swallowfields" width="600" height="450" frameBorder="0" src="https://www.google.com/maps/embed/v1/place?q=place_id:ChIJc_2jGCjaKWsRBoakRPd7G6M&key=AIzaSyBsQCnKGSe_GcA0xxe_kDKUiTImLEN2jk0&zoom=9" allowFullScreen></iframe>
            </div>
        </Fragment>
    );
}

export default Map;