import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Header from "./Header";
import Rsvp from "../features/rsvp/Rsvp";
import ImageGallery from "./ImageGallery";
import RsvpResponses from "./RsvpResponses";

// const mapDispatchToProps = { // Object shorthand form
//   increment
// };
// function mapStateToProps(state) {
//   return {
//     counter: state.counter.value,
//   }
// }

function App() {
  return (
    <Router>
      <Switch>
        <Route path="/responses">
          <RsvpResponses />
        </Route>
        <Route path="/">
          <div className="App">
            <Header />
            <div className="container">
              <h1>The two of us</h1>
              <ImageGallery />
            </div>
            <div className="container">
              <p>Please respond by 1st April 2021</p>
              <h1>Enter your full name</h1>
              <Rsvp />
            </div>
          </div>
        </Route>
      </Switch>
    </Router>
  );
}

export default App;
//export default connect(mapStateToProps, mapDispatchToProps)(App);
