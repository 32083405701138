import { createSlice } from "@reduxjs/toolkit";

const rsvpSlice = createSlice({
  name: "rsvp",
  initialState: {
    guests: {},
  },
  reducers: {
    storeGuestlist(state, action) {
      state.guests = action.payload;
      state.responseReturned = true;
      state.errorFlag = false;
    },
    errorResult(state, action) {
      state.responseReturned = true;
      state.errorFlag = true;
    },
  },
});

// 'ducks' pattern
// named export of action creators
export const { storeGuestlist, errorResult } = rsvpSlice.actions;
// default export of reducer function
export default rsvpSlice.reducer;
