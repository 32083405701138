import React from 'react';

function CountDown({DateTo}) 
{
    const difference = new Date(DateTo) - new Date();
    let daysLeft = 0;
    if (difference > 0) {
      daysLeft = Math.floor(difference / (1000 * 60 * 60 * 24));
    }
  
    return (
      <div className="countdown">
        {daysLeft} days to go!
      </div>
    );
  }

  export default CountDown;