import React, { Fragment, useState } from "react";
import Carousel, { Modal, ModalGateway } from "react-images";

// Try this instead. https://jossmac.github.io/react-images/

function ImageGallery() {
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [lightboxIsOpen, setLightboxIsOpen] = useState(false);

  function toggleLightbox(selectedIndex) {
    setLightboxIsOpen(!lightboxIsOpen);
    setSelectedIndex(selectedIndex);
  }

  // const { images, isLoading } = this.props;
  const isLoading = false;
  const images = [
    {
      caption: "Great Ocean Road",
      source: { regular: "images/us/1.jpg", thumbnail: "images/us/1_t.jpg" },
    },
    {
      caption: "Taiwan",
      source: { regular: "images/us/2.jpg", thumbnail: "images/us/2_t.jpg" },
    },
    {
      caption: "Taiwan",
      source: { regular: "images/us/3.jpg", thumbnail: "images/us/3_t.jpg" },
    },
    {
      caption: "Taiwan",
      source: { regular: "images/us/4.jpg", thumbnail: "images/us/4_t.jpg" },
    },
    {
      caption: "Tassie",
      source: { regular: "images/us/5.jpg", thumbnail: "images/us/5_t.jpg" },
    },
    {
      caption: "Tassie",
      source: { regular: "images/us/6.jpg", thumbnail: "images/us/6_t.jpg" },
    },
    {
      caption: "Canada",
      source: { regular: "images/us/7.jpg", thumbnail: "images/us/7_t.jpg" },
    },
    {
      caption: "Canada",
      source: { regular: "images/us/8.jpg", thumbnail: "images/us/8_t.jpg" },
    },
    {
      caption: "Meeniyan",
      source: { regular: "images/us/9.jpg", thumbnail: "images/us/9_t.jpg" },
    },
    {
      caption: "",
      source: { regular: "images/us/10.jpg", thumbnail: "images/us/10_t.jpg" },
    },
    {
      caption: "Tassie",
      source: { regular: "images/us/11.jpg", thumbnail: "images/us/11_t.jpg" },
    },
    {
      caption: "",
      source: { regular: "images/us/12.jpg", thumbnail: "images/us/12_t.jpg" },
    },
  ];

  function MyFooterCaption(props) {
    const { currentView } = props;
    const { caption, author } = currentView;

    return (
      <span>
        {author ? <span>{author.name}</span> : null}
        {author && caption ? " - " : null}
        {caption}
      </span>
    );
  }

  return (
    <Fragment>
      {!isLoading ? (
        <Gallery>
          {images.map(({ author, caption, source }, j) => (
            <Image onClick={() => toggleLightbox(j)} key={source.thumbnail}>
              <img
                alt={caption}
                src={source.thumbnail}
                style={{
                  cursor: "pointer",
                  // position: 'absolute',
                  // maxWidth: '100%',
                }}
              />
            </Image>
          ))}
          <Image />
        </Gallery>
      ) : null}

      <ModalGateway>
        {lightboxIsOpen && !isLoading ? (
          <Modal
            onClose={toggleLightbox}
            allowFullscreen={false}
            closeOnBackdropClick={true}
          >
            <Carousel
              components={{ FooterCaption: MyFooterCaption }}
              currentIndex={selectedIndex}
              // formatters={{ getAltText }}
              //frameProps={{ autoSize: 'height' }}
              views={images}
            />
          </Modal>
        ) : null}
      </ModalGateway>
    </Fragment>
  );
}

// https://css-tricks.com/adaptive-photo-layout-with-flexbox/

const Gallery = (props) => <ul className="gallery" {...props} />;

const Image = (props) => <li {...props} />;

export default ImageGallery;
