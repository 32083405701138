import { combineReducers } from 'redux'
import counterReducer from '../features/counter/counterSlice'
import todosReducer from '../features/todo/todosSlice'
import rsvpReducer from '../features/rsvp/rsvpSlice'


export default combineReducers({
  counter: counterReducer,
  todos: todosReducer,
  rsvp: rsvpReducer
});