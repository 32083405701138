import React, { useState } from "react";
import axios from "axios";
import { useSelector } from "react-redux";
import Map from "./../../components/Map";
import { useForm } from "react-hook-form";
import AddToCalendar from "react-add-to-calendar";

function RsvpForm() {
  const { register, errors, handleSubmit } = useForm();
  const [submittingText, setSubmittingText] = useState("");
  const [submitDisabled, setSubmitDisabled] = useState(false);
  const guests = useSelector((state) => state.rsvp.guests);
  const responseReturned = useSelector((state) => state.rsvp.responseReturned);
  const errorFlag = useSelector((state) => state.rsvp.errorFlag);

  let event = {
    title: "Eva & Nelson's Wedding",
    description: "",
    location:
      "Swallowfield Vineyards, Swallowfield Rd, Gembrook VIC 3783, Australia",
    startTime: "2021-05-16T11:30:00+10:00",
    endTime: "2021-05-16T16:00:00+10:00",
  };

  let items = [
    { apple: "Apple Calendar" },
    { google: "Google" },
    { outlook: "Outlook" },
    { outlookcom: "Outlook.com" },
  ];

  const onSubmit = (data) => {
    console.log(data);

    setSubmitDisabled(true);
    setSubmittingText("Submitting...");
    axios
      .post(`${process.env.REACT_APP_RSVP_SERVICE}submitrsvp`, data)
      .then((response) => {
        setSubmittingText("Got it thanks!😊");
      })
      .catch((response) => {
        setSubmittingText("Failed to send, sorry 😢");
      })
      .finally(() => {
        setSubmitDisabled(false);
      });
  };

  if (responseReturned) {
    if (errorFlag) {
      return <div>Problem with lookup</div>;
    } else if (
      guests == null ||
      guests.guests == null ||
      !guests.guests.length
    ) {
      return <div>No guest found</div>;
    } else {
      return (
        <form onSubmit={handleSubmit(onSubmit)}>
          <h2>You are invited!</h2>

          <p>
            16th May 2021
            <br />
            Beginning at 11:30am
            <br />
            Ceremony at noon
            <br />
            Lunch reception to follow
          </p>
          <p>Dress code is Cocktail / Semi-formal</p>
          <p>
            <AddToCalendar event={event} listItems={items} />
          </p>

          <h2>Where is it?</h2>
          <div>
            <p>
              Swallowfield Vineyards
              <br />
              Swallowfield Road, Gembrook, Victoria
            </p>
            <p>Parking is available on site</p>
            <Map />
          </div>

          <h2>A note on gifts</h2>
          <p>
            Your presence on our wedding day already means everything. However,
            if you would like to help us along the way with a monetary gift, a
            wishing well will be available at the reception for your well
            wishes.
          </p>

          <h2>Tick if you are attending</h2>
          <input
            type="hidden"
            name="GroupId"
            value={guests.group_id}
            ref={register}
          />
          {guests.guests.map((guest, index) => {
            return (
              <div key={guest.id}>
                <input
                  type="hidden"
                  name={`GuestRsvps[${index}].Id`}
                  value={guest.id}
                  ref={register}
                />
                <input
                  type="hidden"
                  name={`GuestRsvps[${index}].GuestName`}
                  value={guest.name}
                  ref={register}
                />
                <label htmlFor={`GuestRsvps[${index}].Attending`}>
                  <input
                    type="checkbox"
                    id={`GuestRsvps[${index}].Attending`}
                    name={`GuestRsvps[${index}].Attending`}
                    defaultChecked={guest.attending}
                    value="true"
                    ref={register}
                  />
                  {guest.name}
                </label>
              </div>
            );
          })}

          <div className="rsvpMessageSection">
            <p>
              Send a message to Eva and Nelson, and include any dietary
              requirements
            </p>
            <textarea
              name="Message"
              className="textinput"
              rows="4"
              ref={register({ required: true })}
            ></textarea>

            {errors.Message && <p class="error">Message is required</p>}
          </div>
          <input type="submit" disabled={submitDisabled} />
          <div id="submittingText">{submittingText}</div>
          <br />
          <br />
          <img
            src="images/swallowfields.jpg"
            width="100%"
            alt="Swallowfield Vineyards"
          />
        </form>
      );
    }
  } else {
    // initial state
    return null;
  }
}

export default RsvpForm;
